"use client";

import { Avatar, AvatarGroup } from "@chakra-ui/react";

const AvatarGroupClient = () => {
  return (
    <AvatarGroup size="md" max={4}>
      <Avatar src="/assets/images/avatar_08.png" />
      <Avatar src="/assets/images/avatar_07.png" />
      <Avatar src="/assets/images/avatar_02.png" />
      <Avatar
        name="+2k"
        fontSize="12px"
        bg="brand.primary"
        color="brand.secondary"
        textTransform="none"
        getInitials={(name) => name.toLocaleLowerCase()}
      />
    </AvatarGroup>
  );
};

export default AvatarGroupClient;
