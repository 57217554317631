const validations = {
  passwordStrength: {
    value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
    message:
      "Password must be 8 or more characters long, contain at least one capital, one lowercase, one number and one non-alphanumeric symbol (eg. Sw1ftmedic@l)",
  },
  name: {
    value: /^[A-Za-z\s'-]+$/,
    message: "Please enter only letters, dashes, or apostrophes.",
  },
  email: {
    value: /\S+@\S+\.\S+/,
    message: "Please enter a valid email address.",
  },
  noFutureDate: (value: string) => {
    return new Date(value) <= new Date() || "Date cannot be in the future.";
  },
};

export default validations;
