"use client";

import {
  Box,
  Button,
  Heading,
  FormControl,
  Input,
  useToast,
  Text,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useRouter } from "next/navigation";
import { useState } from "react";
import {
  PostSocialSecurity,
  SocialSecurityPayload,
} from "@/actions/auth/PostSocialSecurity";
import { getLeadSource, getUTMParam } from "@/utils/helpers";
import LocationSelect from "@/components/LocationSelect";
import { Controller, useForm } from "react-hook-form";
import { EVENT_LIBRARY, REQUIRED_FIELD } from "@/lib/constants";
import PhoneNumberInput from "../forms/PhoneNumberInput";
import validations from "@/utils/validations";
import { Link } from "@chakra-ui/next-js";
import useAnalytics from "@/lib/hooks/useAnalytics";
import handleServerAction from "@/lib/hooks/handleServerAction";

const LeadForm = () => {
  const track = useAnalytics();
  const router = useRouter();
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm<SocialSecurityPayload>({
    defaultValues: {
      FullName: "",
      Email: "",
      PhoneNumber: "",
      LocationId: undefined,
      TenantId: null,
      LeadSource: getLeadSource(),
      UtmSource: getUTMParam("utm_source"),
      UtmMedium: getUTMParam("utm_medium"),
      UtmCampaign: getUTMParam("utm_campaign"),
    },
  });

  const onSubmit = async (payload: SocialSecurityPayload) => {
    try {
      setIsLoading(true);

      const response = await handleServerAction(PostSocialSecurity, payload);
      toast({
        status: "success",
        title: "Information Submitted",
        description: "Please proceed to the next step!",
      });

      track(EVENT_LIBRARY.SUBMITTED_LEAD);

      const queryString = new URLSearchParams({
        id: response.id!,
        fullName: payload.FullName!,
        email: payload.Email!,
        phoneNumber: payload.PhoneNumber!,
        locationId: payload.LocationId!.toString(),
      }).toString();

      // Navigate to the new page with query string
      router.push(`/prequalify?${queryString}`);
    } catch (err) {
      toast({
        status: "error",
        title: "Error Occured",
        description: err.message,
      });
      setIsLoading(false);
    }
  };

  return (
    <Box
      bg="white"
      borderRadius="8px"
      minW={{
        base: "0px",
        md: "450px",
      }}
      boxShadow="0px 0px 20px 0px rgba(14, 41, 50, 0.17)"
      p={{
        base: "20px",
        md: "45px",
      }}
      zIndex="1"
      as="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Heading
        as="h4"
        fontSize="26px"
        fontWeight="700"
        letterSpacing="-0.52px"
        mb="34px"
        textAlign="center"
        color="brand.secondary"
      >
        See if you pre-qualify!
      </Heading>
      <FormControl mb="20px" isInvalid={errors.FullName ? true : false}>
        <Input
          type="text"
          size="lg"
          placeholder="Full Name"
          {...register("FullName", {
            required: REQUIRED_FIELD,
            minLength: 3,
            pattern: validations.name,
          })}
        />
        <FormErrorMessage>
          {errors.FullName && errors.FullName.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl mb="20px" isInvalid={errors.Email ? true : false}>
        <Input
          type="email"
          placeholder="Email Address"
          size="lg"
          {...register("Email", {
            required: REQUIRED_FIELD,
            pattern: validations.email,
          })}
        />
        <FormErrorMessage>
          {errors.Email && errors.Email.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl mb="20px" isInvalid={errors.PhoneNumber ? true : false}>
        <PhoneNumberInput
          register={register}
          size="lg"
          placeholder="Phone Number"
        />
        <FormErrorMessage>
          {errors.PhoneNumber && errors.PhoneNumber.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl mb="20px" isInvalid={errors.LocationId ? true : false}>
        <Controller
          name="LocationId"
          control={control}
          rules={{ required: REQUIRED_FIELD }}
          render={({ field }) => (
            <LocationSelect
              size="lg"
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <FormErrorMessage>
          {errors.LocationId && errors.LocationId.message}
        </FormErrorMessage>
      </FormControl>
      <Button
        borderRadius="8px"
        bg="brand.secondary"
        py="15px"
        px="28px"
        fontSize="18px"
        lineHeight="24px"
        fontWeight="700"
        color="white"
        w="100%"
        h="54px"
        type="submit"
        isLoading={isLoading}
        _hover={{
          bg: "#2D2D3E",
          textDecoration: "none",
        }}
      >
        Check Eligibility
      </Button>
      <Text mt="12px" textAlign="center" fontSize="14px">
        You agree to our{" "}
        <Link as="span" variant="underlined" href="/terms" target="_blank">
          Terms & Conditions
        </Link>{" "}
        and{" "}
        <Link as="span" variant="underlined" href="/privacy" target="_blank">
          Privacy Policy
        </Link>{" "}
        when submitting your information.
      </Text>
    </Box>
  );
};

export default LeadForm;
