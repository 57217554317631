import { useCallback } from "react";
import { sendGAEvent } from "@next/third-parties/google";
import posthog from "posthog-js";

const useAnalytics = (channels = ["Google", "Posthog"]) => {
  const track = useCallback(
    (eventName: string, properties: any = {}) => {
      if (channels.includes("Google")) {
        sendGAEvent("event", eventName, properties);
      }
      if (channels.includes("Posthog")) {
        posthog.capture(eventName, properties);
      }
      if (channels.includes("Meta")) {
        console.log("TODO: TRACK META PIXEL", eventName, properties);
      }
    },
    [channels]
  );

  return track;
};

export default useAnalytics;
