"use client";
import {
  Box,
  Container,
  Heading,
  HStack,
  Text,
  VStack,
  Flex,
} from "@chakra-ui/react";
import { ContentfulBlogPost } from "@/lib/types";
import Image from "next/image";
import { Link } from "@chakra-ui/next-js";

const BlogSection = ({ posts }: { posts: ContentfulBlogPost[] }) => {
  return (
    <Container maxW="container.xl" mt="136px" mb="68px">
      <HStack
        w="100%"
        justifyContent="space-between"
        alignItems="flex-end"
        mb="56px"
      >
        <VStack alignItems="flex-start" maxW="550px">
          <Heading
            as="h3"
            mb="21px"
            color="brand.secondary"
            fontSize="48px"
            fontWeight="800"
          >
            From our blog
          </Heading>
          <Text
            fontSize="18px"
            color="#3D565F"
            fontWeight="500"
            lineHeight="23px"
          >
            News and information you need to know about your Social Security
            benefits from the experts at Mindset.
          </Text>
        </VStack>
        <Link href="/blog">View All</Link>
      </HStack>
      <Flex layerStyle="responsive" gap="30px" alignItems="flex-start">
        {posts.slice(0, 4).map((post) => (
          <Box
            key={post.fields.slug}
            border="1px solid #CDE2E7"
            p="20px"
            flex="1"
          >
            <Box w="100%">
              <Image
                alt={post.fields.title}
                src={"https:" + post.fields.featuredImage.fields.file.url}
                width={
                  post.fields.featuredImage.fields.file.details.image.width
                }
                height={
                  post.fields.featuredImage.fields.file.details.image.height
                }
              />
            </Box>
            <Link
              href={`/blog/${post.fields.slug}`}
              _hover={{
                textDecoration: "none",
              }}
            >
              <Heading
                as="h5"
                color="brand.secondary"
                fontSize="18px"
                fontWeight="700"
                lineHeight="24px"
                mb="12px"
                mt="12px"
              >
                {post.fields.title}
              </Heading>
            </Link>
            <Text
              color="#3D565F"
              fontWeight="500"
              fontSize="12px"
              lineHeight="18px"
            >
              {post.fields.shortDescription}
            </Text>
            <Box h="1px" w="100%" bg="#ECF1F2" mt="27px" mb="18px"></Box>
            <HStack>
              <Box
                borderRadius="4px"
                borderWidth="1px"
                borderColor="brand.secondary"
                p="5px 10px 8px 11px"
                fontSize="12px"
                fontWeight="700"
                color="brand.secondary"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Text as="span">{post.fields.category || "Information"}</Text>
              </Box>
            </HStack>
          </Box>
        ))}
      </Flex>
    </Container>
  );
};

export default BlogSection;
